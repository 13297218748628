// SpeechCard

import React, { FC } from 'react';
import { Image } from '../Image';

import styles from './SpeechCard.module.scss';

interface Props {
  SpeechCardData: { [key: string]: string };
}

const SpeechCard: FC<Props> = (props) => {
  const { SpeechCardData } = props;
  const speechBgimg = 'https://p0.meituan.net/smartvenus/76a4a12a4c5888bdf6297f3923ed3cdb1796.png';

  return (
    <div className={styles['speech-card-box']}>
      <div className={styles['speech-img-box']}>
        <Image src={speechBgimg} layout="fill" objectFit="cover" />
      </div>
      <p className={styles['card-content']}>{SpeechCardData.content}</p>
      <div className={styles['card-src']}>{SpeechCardData.src}</div>
    </div>
  );
};

SpeechCard.displayName = 'SpeechCard';

export default SpeechCard;
