import React, { FC, PropsWithChildren, ElementType, useState, useEffect, ReactNode } from 'react';
// import { IMtvMapApis } from '@cap/mtv-map';
// import { clearWx } from './utils';

export interface MapProps extends PropsWithChildren {
  sceneConfig: { [key: string]: any };
  mapConfig: { [key: string]: any };
  onLoaded?: (formRef: any) => void;
  // isReady: boolean; // 记录是否ready
}
export const MtvMap: FC<MapProps> = (props) => {
  const [MtvMapComp, setMtvMapComp] = useState<ElementType>();
  const [MarkerLayer, setMarkerLayer] = useState<any>();
  const { children, sceneConfig, mapConfig, onLoaded } = props;

  const onMapLoad = (mapRef: any) => {
    if (typeof onLoaded === 'function') onLoaded(mapRef);
  };

  useEffect(() => {
    // if (!isReady) return; // 如果没有Ready就先不展示页面
    const initMtvMap = () => {
      return import('@cap/mtv-map').then(({ MtvMap: ResMtvMap, MarkerLayer: MarkerLayerRes }) => {
        setMtvMapComp(ResMtvMap);
        setMarkerLayer(MarkerLayerRes);
      });
    };

    // 初始化地图
    initMtvMap();
    // clearWx(initMtvMap);
  }, []);

  if (!MtvMapComp) return null;

  return (
    <MtvMapComp sceneConfig={sceneConfig} mapConfig={mapConfig} onLoaded={onMapLoad}>
      <MarkerLayer>{children}</MarkerLayer>
    </MtvMapComp>
  );
};

export interface MarkerProps extends PropsWithChildren {
  lnglat: { lng: number; lat: number };
  offsets?: [number, number]; // [x,y]
  // isReady?: boolean; // 是否已经ready了
  // eslint-disable-next-line react/no-unused-prop-types
  adCode?: string; // 地图编码
  // eslint-disable-next-line react/no-unused-prop-types
  parentAdCode?: string; // 地理位置编码的父场景
}
export const Marker: FC<MarkerProps> = (props) => {
  const [renderNode, setRenderNode] = useState<ReactNode>();
  const { lnglat, offsets, children } = props;

  useEffect(() => {
    // if (!isReady) return; // 如果没有Ready就先不展示页面
    const init = async () => {
      const MtvMarker = await import('@cap/mtv-map').then(({ Marker: ResMarker }) => ResMarker);
      const renderComp = (
        <MtvMarker lnglat={lnglat} offsets={offsets} anchor="bottom">
          {children}
        </MtvMarker>
      );
      setRenderNode(renderComp);
    };

    init();
  }, [lnglat, children, offsets]);

  return <>{renderNode}</>;
};
