import React, { FC, useEffect } from 'react';
import SuctionWrap from '../../components/SuctionWrap';
import { H5Button } from '../Laozihao/components/H5Button';

import styles from './FloatingWindow.module.scss';
import { Image } from '../../components/Image';

interface IProps {
  h5jumpData?: {
    jumpUrl: string;
    title: string;
  };
  FloatCardData: Record<string, any>;
}

const FloatingWindow: FC<IProps> = (props) => {
  const { h5jumpData, FloatCardData } = props;

  useEffect(() => {
    let placeholderSuction: HTMLElement;
    if (window) {
      placeholderSuction = document.createElement('div');
      placeholderSuction.className = styles['footer-suction-placeholder'];
      document.body.appendChild(placeholderSuction);
    }

    return () => {
      if (placeholderSuction) {
        document.body.removeChild(placeholderSuction);
      }
    };
  }, []);

  const onJump = () => {
    if (h5jumpData) {
      window.location.href = h5jumpData.jumpUrl;
    }
  };

  return (
    <>
      {/* pc样式 */}
      <div className="hidden lg:block">
        <div className={styles['win-card-box']}>
          <div className={styles['win-card-logo']}>
            <Image src={FloatCardData.logo} width={80} height={62} layout="responsive" />
          </div>
          <div className={styles['win-card-box-container']}>
            <div className={styles['win-card-qr-img-box']}>
              <Image src={FloatCardData.qrImg} width={100} height={100} layout="responsive" />
            </div>
            <div className={styles['win-card-con-warp']}>
              <div className={styles['win-card-name']}>{FloatCardData.intro1}</div>
              <div className={styles['win-card-dec']}>{FloatCardData.intro2}</div>
            </div>
          </div>
        </div>
      </div>
      {/* h5样式 */}
      {/* 所有页面都不展示我要捐赠的页面 */}
      {h5jumpData && (
        <div className="block lg:hidden">
          <SuctionWrap>
            <div className={styles['footer-wrap']}>
              <div className={styles['footer-container']}>
                <H5Button title={h5jumpData.title} onClick={onJump} />
              </div>
            </div>
          </SuctionWrap>
        </div>
      )}
    </>
  );
};

FloatingWindow.displayName = 'FloatingWindow';

export default FloatingWindow;
