import React from 'react';
import classNames from 'classnames';
import MTDIcon from '../../../components/Icon/MTDIcon';
import styles from './index.module.scss';

interface Props {
  onAddZoom(): void;
  onReduceZoom(): void;
  type?: 'h5' | 'pc';
}

export const ZoomControl = (props: Props) => {
  const { onAddZoom, onReduceZoom, type = 'pc' } = props;

  return (
    <div
      className={classNames(styles['zoom-control'], {
        [`${styles['zoom-control-pc']}`]: type === 'pc',
        [`${styles['zoom-control-h5']}`]: type === 'h5',
      })}
    >
      <div className={classNames(styles['zoom-control-plus'], styles['zoom-control-button'])} onClick={onAddZoom}>
        <MTDIcon icon="mtdicon-add" height="14" width="14" />
      </div>
      <div className={classNames(styles['zoom-control-minus'], styles['zoom-control-button'])} onClick={onReduceZoom}>
        <MTDIcon icon="mtdicon-remove" height="14" width="14" />
      </div>
    </div>
  );
};
