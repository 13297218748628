import BN from 'bignumber.js';

type NumberValue = number | string;
type Decimal = number;

/**
 * 格式化
 * @param {BN} num
 * @param {Number} decimal
 */
export function format(num: BN, decimal?: Decimal) {
  return num.toFormat(decimal as any, BN.ROUND_HALF_UP);
}

export function formatWithoutEndZero(num: BN, decimal?: Decimal) {
  // 四舍五入，加逗号分隔
  const resultString = num.toFormat(decimal as any, BN.ROUND_HALF_UP);
  // 去掉小数末尾的.0
  if (resultString.includes('.')) {
    return resultString.replace(/\.?0+$/, '');
  }
  return resultString;
}

/**
 * 格式化次数-新版
 * @param {number} num
 * @returns {Object} { num, unit }
 */
export function formatNum(num: NumberValue, lowest = 1e4) {
  const _num = new BN(num);
  if (_num.isGreaterThanOrEqualTo(1e8)) {
    return {
      num: format(_num.div(1e8), 1),
      unit: '亿',
    };
  }

  if (_num.isGreaterThanOrEqualTo(lowest)) {
    return {
      num: format(_num.div(lowest), 1),
      unit: '万',
    };
  }

  return {
    num: format(_num),
    unit: '',
  };
}

/**
 * 根据配置进行数字处理
 */
export function formatNumWithConfig(
  num: NumberValue,
  decimalConfig: { decimal: Decimal; unit: string; point?: number }[],
) {
  const _num = new BN(num);

  if (Array.isArray(decimalConfig) && decimalConfig.length) {
    // 排序，数字越大越先处理
    const sortCecimalConfig = [...decimalConfig].sort((a, b) => b.decimal - a.decimal);
    for (const decimalConfigItem of sortCecimalConfig) {
      const { unit, decimal, point = 1 } = decimalConfigItem;
      if (_num.isGreaterThanOrEqualTo(decimal)) {
        return {
          num: format(_num.div(decimal), point),
          unit,
        };
      }
    }
  }

  return {
    num: format(_num),
    unit: '',
  };
}

/**
 * 专门为公益支持的数字处理
 */
export function formatNumForGongyi(num: NumberValue, options?: { lanType?: string }) {
  const { lanType = 'zh_CN' } = options || {};
  let decimalConfig = [
    { decimal: 1e4, unit: '万', point: 1 },
    { decimal: 1e8, unit: '亿', point: 1 },
  ];
  if (lanType === 'en-US') {
    decimalConfig = [{ decimal: 1e3, unit: 'K', point: 1 }];
  }

  return formatNumWithConfig(num, decimalConfig);
}

/**
 * 格式化金额
 * @param {number} num 金额，分为单位
 * @param {number} lowest 最低计算金额
 */
export function formatMoney(num = 0, lowest = 1e4) {
  const _num = new BN(num).div(1e2);

  if (_num.isGreaterThanOrEqualTo(1e8)) {
    return {
      num: format(_num.div(1e8), 2),
      unit: '亿',
    };
  }

  if (_num.isGreaterThanOrEqualTo(lowest)) {
    return {
      num: format(_num.div(1e4), 2),
      unit: '万',
    };
  }

  return {
    num: format(_num, 2),
    unit: '',
  };
}

/**
 * @param {Number} val 当前值
 * @param {Number} total 总值
 */
export function toPercent(val: number, total: number) {
  const percent = Math.floor(((val * 1.0) / total) * 10000) / 100;
  const ratio = percent > 100 ? '100' : percent;

  return `${ratio}%`;
}

// 分转元并格式化为千分字符串
export function formatFen2Yuan(num: number, decimal?: Decimal) {
  if (decimal === undefined) {
    decimal = num % 100 > 0 ? 2 : 0;
  }
  return format(new BN(num).div(1e2), decimal);
}
