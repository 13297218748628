// import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRequest } from '../../hooks/useRequest';
import { getPositionNewsList, NewsCenterItem } from '../../api/newsApi';

export function useNewsFooterData(lanType: string, positionNo: string) {
  const { t } = useTranslation('common');
  // const [newsDate, setNewsDate] = useState<NewsCenterItem[]>([]);

  const newsModuleConfig: {
    newsTitleText: string;
    newsMoreText: string;
  } = t('newsModuleConfig', { returnObjects: true });

  const { res: newsCenterData = [] } = useRequest<any, NewsCenterItem[]>(getPositionNewsList, {
    params: { lanType, positionNo },
  });

  // const queryData = async () => {
  //   const newsCenterData = await getPositionNewsList({ lanType, positionNo }, 3);
  //   setNewsDate(newsCenterData);
  // };

  // useEffect(() => {
  //   queryData().then();
  // }, [lanType, positionNo]);

  return {
    newsDate: newsCenterData || [],
    ...newsModuleConfig,
  };
}
