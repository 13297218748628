import React, { useEffect, useState } from 'react';
import { detectWebGLContext } from './utils';

interface Props {
  children: React.ReactNode;
}

export const NotSupportWebgl = (props: Props) => {
  const [supportWebgl, setSupportWebgl] = useState(false);
  const [hasDetect, setHasDetect] = useState(false);
  const { children } = props;

  useEffect(() => {
    const support = detectWebGLContext();
    setSupportWebgl(support);
    setHasDetect(true);
  }, []);
  if (!hasDetect) {
    return null;
  }
  return (
    <>
      {!supportWebgl && (
        <>
          <div className="hidden lg:block lg:h-[560px]">
            <img
              alt="map placeholder"
              src="https://p0.meituan.net/smartvenus/a05c054dc50b286380e282068a4610d0226621.png"
              style={{ width: 'auto', height: '560px', objectFit: 'cover' }}
            />
          </div>
          <div className="block w-full lg:hidden ">
            <img
              alt="map placeholder"
              src="https://p0.meituan.net/smartvenus/217ab022a8f831869ba7e3c070a66406455239.png"
              style={{ width: '100%', height: '20.625rem' }}
            />
          </div>
        </>
      )}
      {supportWebgl && <>{children}</>}
    </>
  );
};
