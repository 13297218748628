import { format } from 'date-fns';

/**
 * 处理时间为年月展示
 */
export const formatIntYearMonth = (yearNumber: number, monthNumber: number, options?: { locale?: string }) => {
  const { locale } = options || {};
  let ret = '';
  if (locale === 'en-US') {
    const date = new Date(`${yearNumber} ${monthNumber}`);
    const monthStr = format(date, 'LLLL');
    ret = `${monthStr} ${yearNumber}`;
  } else {
    ret = `${yearNumber}年${monthNumber}月`;
  }

  return ret;
};
