import {
  getStatisticPlaygroundData,
  type StatisticPlaygroundDataResContent as PlayGroundResDataType,
} from '../../api/playgroundApi';
import { logger } from '../../util/logger';
import { MapLevel, centerLagLnt, getCenterByAdcode } from './constant';

export const averageLnglat = (list: Array<{ latitude: number; longitude: number }>) => {
  const len = list.length;

  const total = list.reduce(
    (res, item) => {
      res.latitude += item.latitude;
      res.longitude += item.longitude;
      return { ...res };
    },
    { latitude: 0, longitude: 0 },
  );

  return {
    latitude: len > 0 ? total.latitude / len : 0,
    longitude: len > 0 ? total.longitude / len : 0,
  };
};

const cacheMap = new Map<string, PlayGroundResDataType[]>();
/**
 * 发起ajax请求，对缓存结果进行缓存
 */
export const getMarkerDataList = async (params: { acType: MapLevel }, path?: string) => {
  const { acType: level } = params;
  if (cacheMap.has(level)) {
    return cacheMap.get(level) as PlayGroundResDataType[];
  }
  try {
    let { data } = await getStatisticPlaygroundData(params, path);
    // 处理全国数据
    if (level === 'GLOBAL') {
      data = data.map((item) => {
        return {
          ...item,
          latitude: centerLagLnt[0],
          longitude: centerLagLnt[1],
        };
      });
    } else if (level === 'COUNTRY') {
      data = data.map((item) => {
        const center = getCenterByAdcode(item.adCode as any);
        return {
          ...item,
          latitude: center[1],
          longitude: center[0],
        };
      });
    }
    const finalPgStatsLists = data
      // 处理一下数据
      .map((dataItem) => {
        const { latitude, longitude, ...otherData } = dataItem;
        return {
          ...otherData,
          latitude: +latitude || 0,
          longitude: +longitude || 0,
        };
      })
      .filter((markerItemData: PlayGroundResDataType) => markerItemData.latitude && markerItemData.latitude)
      // 这里需要根据纬度来排序，纬度越高越先渲染，这样保持更低维度的Marker展示优先级更高
      .sort((a, b) => b.latitude - a.latitude);
    cacheMap.set(level, finalPgStatsLists);
    return finalPgStatsLists;
  } catch (e) {
    logger.error(`${path}, ${JSON.stringify(params)} 请求错误`);
    return [];
  }
};

export const detectWebGLContext = () => {
  const canvas = document.createElement('canvas');
  // Get WebGLRenderingContext from canvas element.
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  // Report the result.
  if (gl && gl instanceof WebGLRenderingContext) {
    return true;
  }
  return false;
};

export const filterPointByBounds = (opts: { data: Array<{ latitude: number; longitude: number }>; bounds: any }) => {
  const { data = [], bounds } = opts;

  return data.filter((_) => {
    const { sw, ne } = bounds;
    const { lat, lng } = sw;
    if (_.latitude < lat || _.longitude < lng) {
      return false;
    }
    if (_.latitude > ne.lat || _.longitude > ne.lng) {
      return false;
    }
    return true;
  });
};
