import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { type StatisticPlaygroundDataResContent as PlayGroundResDataType } from '../../api/playgroundApi';
import { MtvMap, type MarkerProps } from './MtvMap';
import { MTDIcon } from '../../components/Icon';
import { WithModal as PGDetailModal } from '../../components/PGModalCard';
import { ZoomControl } from './ZoomControl';
import {
  zoomLevelConfig,
  getCurrentLevelConfig,
  getNextStep,
  getPrevStep,
  MapLevel,
  getCurrentStepInfo,
  report,
} from './constant';
import { getMarkerDataList, filterPointByBounds } from './utils';
import { PGMarkerItem } from './PGMarkItem';
import styles from './PGMap.module.scss';
import '@antv/l7-component/es/css/index.css';

// const MAP_KEY = '62267d54-725d-45db-8241-07702021cd81';
const MAP_KEY = 'm391f93085054ce68e06e741ca94e14j';

interface Props {
  opts?: { markerSourcePath: string; markerInfoPath: string };
  onReport?(params: { type: string; payload: any }): void;
  register?(callback: any): void;
  initialMapLevel?: MapLevel; // 默认初始化的地图level等级
  initialCenter?: [number, number]; // 默认地图的中心点
  onFullScreenChange?(state: boolean): void;
  lanType?: string;
}

const defaultZoomMtvLevel = zoomLevelConfig.COUNTRY.level?.[0] || 1;

const sceneConfig = {
  logoVisible: false,
};

const defaultCenter = [108.93, 34.27];

const defaultMapConfig = {
  version: '1.8.0',
  l7Version: '0.0.16',
  key: MAP_KEY,
  center: defaultCenter, // [119.8686, 43.3662],
  pitch: 0,
  zoom: defaultZoomMtvLevel,
  enableDblclickZoom: false,
  style: 'light',
  plugin: ['ZoomControl'],
};

const PGMap = (props: Props) => {
  const loadingCountRef = useRef(0);
  const [loading, setLoadingState] = useState(false);
  const mtvSceneRef = useRef<any>();
  const [markerLists, setMarkerList] = useState<PlayGroundResDataType[]>([]);
  // 地图的缩放level值
  const zoomMtvLevelRef = useRef<number>(defaultZoomMtvLevel);
  // 地图level值换算成业务的MapLevel
  const zoomLevelRef = useRef<MapLevel>(zoomLevelConfig.COUNTRY.value);
  const [isLastZoomLevel, setIsLastZoomLevel] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailSerialNumber, setDetailSerialNumber] = useState<string>();
  const [viewBounds, setViewBounds] = useState<any>();
  const getDataRef = useRef<(level: MapLevel) => Promise<void>>();
  const registerRef = useRef<any>();

  // const { ready } = useConfigShareReady();

  // 处理对外的opts暴露
  const { opts, onReport, register, onFullScreenChange, initialMapLevel, initialCenter, lanType } = props;
  registerRef.current = register;

  const [defaultMapOption] = useState({
    zoom: initialMapLevel ? zoomLevelConfig[initialMapLevel].level[0] : defaultZoomMtvLevel,
    center: initialCenter ? [...initialCenter] : defaultCenter,
  });

  const mapConfig = useMemo(() => {
    return {
      ...defaultMapConfig,
      ...defaultMapOption,
    };
  }, [defaultMapConfig, defaultMapOption]);
  const lxReport = useMemo(() => {
    return report(onReport);
  }, [onReport]);

  // 这里因为是多个地方可能会操作 setLoading 所以用 count 来记数
  const setLoading = useCallback((state: boolean) => {
    const { current: loadingCount } = loadingCountRef;
    if (state) loadingCountRef.current = loadingCount + 1;
    else {
      const finalLoadingCount = loadingCount - 1;
      if (finalLoadingCount <= 0) loadingCountRef.current = 0;
      else loadingCountRef.current = finalLoadingCount;
    }

    setLoadingState(loadingCountRef.current > 0);
  }, []);

  // 查询数据
  getDataRef.current = async (level: MapLevel) => {
    // 检查缓存，假如命中缓存则不再进行请求
    try {
      setLoading(true);
      const pgStatsLists = await getMarkerDataList({ acType: level }, opts?.markerSourcePath);
      if (pgStatsLists) {
        setMarkerList(pgStatsLists);
      } else {
        setMarkerList([]);
      }
    } finally {
      setLoading(false);
    }
  };

  const onMapLoad = async (scene: any) => {
    setLoading(false);
    mtvSceneRef.current = scene;
    scene.setMapStatus({
      keyboardEnable: false, // 是否允许形键盘事件
      zoomEnable: false, // 滚动缩放
    });
    scene?.on('zoomchange', (val: any) => {
      const { data } = val || {};
      const { after: currentLevel } = data || {};
      zoomMtvLevelRef.current = currentLevel;
      const matchZoomLevelConfig = getCurrentLevelConfig(currentLevel);
      if (matchZoomLevelConfig?.value !== zoomLevelRef.current) {
        zoomLevelRef.current = matchZoomLevelConfig?.value as MapLevel;
        // setZoomLevel(matchZoomLevelConfig.value);
        // 判断是否最后一个层级
        getDataRef.current?.(zoomLevelRef.current);
        setIsLastZoomLevel(zoomLevelRef.current === zoomLevelConfig.COUNTY.value);
      }
    });
    scene.map.on('click', () => {
      // 任意的地图点击事件
      lxReport('mapClick', {});
    });
    // map地址的时口发生变化
    scene.map.on('moveend', () => {
      const bounds = scene.map.getBounds();
      setViewBounds(bounds);
    });
  };

  // 点击操场事件
  const onClickPG = async (item: PlayGroundResDataType, options: MarkerProps) => {
    const { lnglat, adCode } = options;
    // 如果点的数量为1。
    if (isLastZoomLevel || item.count === 1) {
      // 假如已经到了最后的缩放级别，则打开操场卡片
      setDetailSerialNumber(item.serialNo);
      setDetailVisible(true);
      // 灵犀上报
      lxReport('cardOpen', { item });
    } else {
      // 假如没有则往下缩放一级
      const zoomLevel = zoomLevelRef.current;
      // 如果地图的SceneRef没有实例化好
      if (!mtvSceneRef.current) return;
      // 需要请求下一级数据, 当层级发生变化时
      const nextMapLevel = getPrevStep(zoomLevel);
      const currentLevelConfig = getCurrentStepInfo(nextMapLevel);
      if (nextMapLevel && nextMapLevel !== zoomLevelRef.current) {
        setLoading(true);
        try {
          const dataList = await getMarkerDataList({ acType: nextMapLevel });
          const arr = dataList?.filter((_) => {
            return _.parentAdCode === adCode;
          });
          // 下一级没有数据，那么就直接以当前位置展开
          if (arr.length === 0) {
            mtvSceneRef.current.setZoomAndCenter(currentLevelConfig.current, [lnglat.lng, lnglat.lat]);
            return;
          }
          const _item = arr[0];
          mtvSceneRef.current.setZoomAndCenter(currentLevelConfig.current, [_item.longitude, _item.latitude]);

          // const center = averageLnglat(arr);
          // mtvSceneRef.current.setZoomAndCenter(currentLevelConfig.current, [center.longitude, center.latitude]);
        } finally {
          setLoading(false);
        }
      } else {
        mtvSceneRef.current.setZoomAndCenter(currentLevelConfig.current, [lnglat.lng, lnglat.lat]);
      }
    }
    // 灵犀上报
    lxReport('mapMarkerClick', { item });
  };

  getDataRef.current = async (level: MapLevel) => {
    // 检查缓存，假如命中缓存则不再进行请求
    try {
      setLoading(true);
      const pgStatsLists = await getMarkerDataList({ acType: level }, opts?.markerSourcePath);
      if (pgStatsLists) {
        setMarkerList(pgStatsLists);
      } else {
        setMarkerList([]);
      }
    } finally {
      setLoading(false);
    }
  };

  const onTriggerFullScreen = () => {
    const state = !isFullScreen;
    const center = mtvSceneRef.current.map.getCenter();
    setFullScreen(state);
    if (mtvSceneRef.current) {
      setTimeout(() => {
        mtvSceneRef.current.panBy(0, 0);
        mtvSceneRef.current.setCenter(center);
      }, 30);
    }
    // 灵犀上报
    lxReport('fullScreenClick', { state });
    /**
     * 优化地图点击全屏按钮的上报功能
     */
    onFullScreenChange?.(state);
  };

  // 地图的缩放
  const onAddZoom = () => {
    const currentLevelConfig = getCurrentLevelConfig(zoomMtvLevelRef.current);
    if (currentLevelConfig && mtvSceneRef.current) {
      const { value } = currentLevelConfig;
      const levelStep = getPrevStep(value);
      const center = mtvSceneRef.current.map.getCenter();
      if (levelStep) {
        const { current } = getCurrentStepInfo(levelStep);
        mtvSceneRef.current.setZoomAndCenter(current, center);
      }
    }
  };
  const onReduceZoom = () => {
    const currentLevelConfig = getCurrentLevelConfig(zoomMtvLevelRef.current);
    if (currentLevelConfig && mtvSceneRef.current) {
      const { value } = currentLevelConfig;
      const levelStep = getNextStep(value);
      const center = mtvSceneRef.current.map.getCenter();
      if (levelStep) {
        const { current } = getCurrentStepInfo(levelStep);
        mtvSceneRef.current.setZoomAndCenter(current, center);
      }
    }
  };
  // 组件初始化
  useEffect(() => {
    // 设置初始化的loading状态
    setLoading(true);
    // 设置回掉事件
    registerRef.current?.((state: boolean) => {
      setFullScreen(state);
      lxReport('fullScreenClick', { state: false });
      if (mtvSceneRef.current) {
        const center = mtvSceneRef.current.map.getCenter();
        setTimeout(() => {
          mtvSceneRef.current.panBy(0, 0);
          mtvSceneRef.current.setCenter(center);
        }, 30);
      }
    });
    // 发起第一次请求, 返回第一次的请求数据
    getDataRef.current?.(initialMapLevel || zoomLevelConfig.COUNTRY.value);
    return () => {
      // 离开时销毁
      mtvSceneRef.current?.destroy();
    };
  }, [lxReport, setLoading, initialMapLevel]);

  const showMarkerList: Array<any> = useMemo(() => {
    if (!Array.isArray(markerLists)) return [];
    const mapLevel = zoomLevelRef.current;
    if ([MapLevel.COUNTY, MapLevel.CITY].includes(mapLevel)) {
      const bounds = viewBounds;
      return filterPointByBounds({ data: markerLists, bounds });
    }
    return markerLists;
  }, [markerLists, viewBounds]);

  const markerListsNode = showMarkerList.map((markerItemData: PlayGroundResDataType) => (
    <PGMarkerItem
      key={`${markerItemData.serialNo}_${markerItemData.adCode}_${markerItemData.parentAdCode}`}
      data={markerItemData}
      showCount={!isLastZoomLevel} // 不是最后一级，而且每个item的count大于1
      onClick={onClickPG}
    />
  ));

  return (
    <Spin
      wrapperClassName={classNames(styles['pgmap-wrapper'], isFullScreen && styles.fullscreen)}
      spinning={loading}
      delay={500}
    >
      {/* <div className="absolute left-0 top-0 z-50 text-left">当前 currentMarksLength: {showMarkerList.length}</div> */}
      <MtvMap sceneConfig={sceneConfig} mapConfig={mapConfig} onLoaded={onMapLoad}>
        {markerListsNode}
      </MtvMap>
      <div
        className={classNames(styles['btns-h5'], {
          [`${styles.isOpen}`]: isFullScreen,
        })}
      >
        {/* <ZoomControl type="h5" onAddZoom={onAddZoom} onReduceZoom={onReduceZoom} /> */}
        <div className={styles['fullscreen-btn']} onClick={onTriggerFullScreen}>
          <MTDIcon
            height="24px"
            width="24px"
            icon={isFullScreen ? 'mtdicon-exit-fullscreen-o' : 'mtdicon-fullscreen-o'}
          />
        </div>
      </div>
      <ZoomControl onAddZoom={onAddZoom} onReduceZoom={onReduceZoom} />
      <PGDetailModal
        visible={detailVisible}
        lanType={lanType}
        serialNumber={detailSerialNumber}
        onClose={() => {
          setDetailVisible(false);
          lxReport('popupClose', { detailSerialNumber });
        }}
        width={760}
        changeTab={(params: any) => {
          lxReport('popupTabToggle', params);
        }}
        opts={opts}
      />
    </Spin>
  );
};

export default PGMap;
