import React, { FC } from 'react';
import { Image } from '../../components/Image';
import ImgTextCard from '../../components/ImgTextCard';
import SpeechCard from '../../components/SpeechCard';
import styles from './PGCharity.module.scss';

interface Props {
  PGCharityNoteData: { [key: string]: string };
  PGCharityNoteCardList: Array<{ [key: string]: string }>;
  PGReflectionData: { [key: string]: string };
}
const PGCharity: FC<Props> = (props) => {
  const { PGCharityNoteData, PGCharityNoteCardList, PGReflectionData } = props;
  const [PGCharityNoteCard1, PGCharityNoteCard2] = PGCharityNoteCardList;
  return (
    <section className={styles['pg-charity-box']}>
      <h2 className={styles['pg-charity-title']}>{PGCharityNoteData.title}</h2>
      <div className={styles['pg-charity-intro-box']}>
        <p>{PGCharityNoteData.intro1}</p>
        <p>{PGCharityNoteData.intro2}</p>
        <p>{PGCharityNoteData.intro3}</p>
      </div>
      <div className={styles['pg-charity-img-text-box']}>
        {/* <div className={styles['pg-charity-img-text-wrap']}>
          <ImgTextCard ImgTextCardData={PGCharityNoteCard1} />
        </div> */}

        {/* 善款截图展示模块 */}
        <div className={styles['screenshot-card-box']}>
          <div className={styles['card-img-box']}>
            <Image src={PGCharityNoteCard1.img} layout="fill" objectFit="cover" />
          </div>
          <div className={styles['text-box']}>
            <p className={styles['text-val']}>{PGCharityNoteCard1.title1}</p>
            <p className={styles['text-val']}>{PGCharityNoteCard1.title2}</p>
          </div>
        </div>
        <div className={styles['pg-charity-img-text-wrap']}>
          <ImgTextCard ImgTextCardData={PGCharityNoteCard2} />
        </div>
      </div>
      <div className={styles['speech-card-wrap']}>
        <div className={styles['speech-img']}>
          <Image src={PGReflectionData.img} layout="fill" objectFit="cover" />
        </div>

        <SpeechCard SpeechCardData={PGReflectionData} />
      </div>
    </section>
  );
};
PGCharity.defaultProps = {};

PGCharity.displayName = 'PGCharity';
export default PGCharity;
