import React from 'react';
import styles from './index.module.scss';

interface Props {
  onClick(): void;
  title?: string;
}

export const H5Button = ({ onClick, title }: Props) => {
  return (
    <button className={styles.h5button} onClick={onClick}>
      {title}
    </button>
  );
};
