import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export { IndexDescription } from './IndexDescription';

interface Props {
  title: string; // 模块标题
  count: string; // 模块中的数量
  unit: string; // 模块中的单位
  index: Array<{
    amount: string;
    unit: string;
    remark: string;
  }>;
}
const getUnit = (value: string) => {
  return value.replace(/^\d+(\.\d+)?/g, '');
};

export const GyIndexCard = (props: Props) => {
  const { title, count, unit, index } = props;

  const cls = classNames('mt-font', styles.totalValue);
  return (
    <div className={styles.indexBorderContainer}>
      <div className={styles.indexContainer}>
        <div className={styles.daishu} />
        <div className={styles.total}>
          <div className={styles.totalName}>{title}</div>
          <div className={styles.totalCount}>
            <span className={cls}>{count}</span>
            <span className={styles.totalUnit}>{unit}</span>
          </div>
        </div>
        <div className={styles.grid}>
          {index.map((_, key) => {
            const inUnit = getUnit(_.amount);
            const realUnit = `${inUnit}${_.unit}`;
            const realValue = _.amount.replace(inUnit, '');
            return (
              <div className={styles.gridItem} key={key}>
                <span className={styles.indexValue}>
                  <span className={`${styles.count} mt-font`}>{realValue}</span>
                  <span className={styles.unit}>{realUnit}</span>
                </span>
                <label className={styles.indexLabel}>{_.remark}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
