import React, { FC } from 'react';
import TitleLink from '../../components/TitleLink';
import NewsItem from '../../components/NewsList/NewsItem';

import styles from './NewsFooter.module.scss';
import { useNewsFooterData } from './hook';

export interface DataType {
  [key: string]: string;
}

interface Props {
  FooterTheme?: string;
  lanType: string;
  positionNo: string;
  category?: number;
}

const NewsFooter: FC<Props> = (props) => {
  const { FooterTheme, lanType, positionNo, category } = props;
  const imgLgClass = 'overflow-hidden w-full rounded-lg lg:rounded-2xl translate-y-0'; // lg:w-25.88r
  const contentLgClass = 'lg:h-4.57r lg:flex-1';
  const hasAbstract = false;

  const { newsDate, newsTitleText, newsMoreText } = useNewsFooterData(lanType, positionNo);

  return (
    <section className={styles['footer-news-box']}>
      <div className={styles['footer-news-wrap']}>
        <div className={styles['footer-title-link-box']}>
          <TitleLink
            textVal={newsTitleText}
            linkVal={newsMoreText}
            titleLinkTheme={FooterTheme}
            linkUrl={category ? `/news?category=${category}` : '/news'}
            linkOut
          />
        </div>

        <div className={styles['news-item-box']}>
          {newsDate?.map((item, index) => (
            <div className={styles['news-item-box-wrap']} key={index}>
              <NewsItem
                newsItemData={item}
                contentLgClass={contentLgClass}
                imgLgClass={imgLgClass}
                hasAbstract={hasAbstract}
                newsItemTheme={FooterTheme}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

NewsFooter.displayName = 'NewsFooter';
export default NewsFooter;
