import React, { FC } from 'react';

import { Image } from '../Image';
import styles from './ImgTextCard.module.scss';

interface Props {
  ImgTextCardData: { [key: string]: string };
}

const ImgTextCard: FC<Props> = (props) => {
  const { ImgTextCardData } = props;

  return (
    <div className={styles['img-text-card-box']}>
      <div className={styles['card-img-box']}>
        <Image width={152} height={68} src={ImgTextCardData.img} />
      </div>
      <div className={styles['text-box']}>
        <p className={styles['text-val']}>{ImgTextCardData.title1}</p>
        <p className={styles['text-val']}>{ImgTextCardData.title2}</p>
      </div>
      <div className={styles['remark-val']}>{ImgTextCardData.remark}</div>
    </div>
  );
};

ImgTextCard.displayName = 'ImgTextCard';

export default ImgTextCard;
