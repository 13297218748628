import React, { FC, ReactNode, PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Image } from '../Image';

import styles from './CommonBanner.module.scss';

interface BgSloganType {
  content: string;
  sign: string;
}

export interface Props extends PropsWithChildren {
  /**
   * 背景图片
   */
  bgUrl: string;

  /**
   * 背景图片的 className
   */
  bgCls?: string;

  /**
   * 背景的宣传标语
   */
  bgSlogan?: BgSloganType;

  /**
   * 内容卡片 className
   */
  cardCls?: string;

  cardBodyCls?: string;

  /**
   * banner 标题
   */
  title?: string;

  /**
   * 描述内容
   */
  description?: string | string[];

  /**
   * 额外的信息
   */
  extra?: ReactNode;
  /**
   * 头图模式
   */
  topBannerModel?: string;

  enableGradient?: boolean;
}

const CommonBanner: FC<Props> = (props) => {
  const {
    bgUrl,
    bgCls,
    bgSlogan,
    cardCls,
    cardBodyCls,
    title,
    description,
    extra,
    children,
    topBannerModel,
    enableGradient = false,
  } = props;

  const defaultBgCls = 'h-21.25r lg:h-37.5r';
  const bgClsName = bgCls || defaultBgCls;

  const descNode = Array.isArray(description)
    ? description.map((descItem, idx) => <div key={idx}>{descItem}</div>)
    : description;

  // 计算图片是否有遮照层
  const imgCls = classNames(
    styles['common-banner-card-image-content'],
    bgClsName,
    bgSlogan ? styles['common-banner-card-image-content-with-cover'] : '',
  );

  const topBgImg = topBannerModel === 'bg' ? bgUrl : '';
  const topBgStyle = {
    backgroundImage: `url(${topBgImg})`,
  };

  return (
    <div className={styles['common-banner-card-container']}>
      <div className={styles['common-banner-card-image-container']}>
        {topBannerModel === 'bg' ? (
          <div className={imgCls} style={topBgStyle} />
        ) : (
          <div className={imgCls}>
            <Image objectFit="cover" src={bgUrl} layout="fill" />
          </div>
        )}
        {enableGradient ? <div className={styles['common-gradient']} /> : null}
        {bgSlogan && (
          <div className={styles['common-banner-card-imgae-slogan']}>
            <div className={styles['common-banner-card-image-slogan-content']}>{bgSlogan.content}</div>
            <div className={styles['common-banner-card-image-slogan-sign']}>{bgSlogan.sign}</div>
          </div>
        )}
      </div>
      <div className={classNames(styles['common-banner-card-content-wrap'], cardCls, 'px-5 xl:px-0')}>
        <div className={classNames(styles['common-banner-card-content-container'], cardBodyCls)}>
          {title && <div className={styles['common-banner-card-content-title']}>{title}</div>}
          {description && <div className={styles['common-banner-card-content-desc']}>{descNode}</div>}
          {(extra || children) && (
            <div className={styles['common-banner-card-extra']}>
              {extra}
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CommonBanner.displayName = 'CommonBanner';
CommonBanner.defaultProps = {
  bgUrl: '',
  title: '',
  description: '',
  extra: '',
};

export default CommonBanner;
