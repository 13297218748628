import React from 'react';
import styles from './index.module.scss';

export const IndexDescription = ({ title }: { title: string }) => {
  return (
    <div className={styles['dataSrc-box-wrap']}>
      <div className={styles['dataSrc-box']}>{title}</div>
    </div>
  );
};
