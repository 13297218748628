import React, { useMemo } from 'react';
import { type StatisticPlaygroundDataResContent as PlayGroundResDataType } from '../../api/playgroundApi';
import { Marker, type MarkerProps } from './MtvMap';
import { Image } from '../../components/Image';
import styles from './PGMap.module.scss';

interface MarkerItemProps {
  data: PlayGroundResDataType;
  showCount: boolean;
  onClick?: (item: PlayGroundResDataType, options: MarkerProps) => void;
}

export const PGMarkerItem = (props: MarkerItemProps) => {
  const { data, showCount, onClick } = props;
  const { imgSrc, count, latitude, longitude, adCode } = data;
  const lnglat = useMemo(() => ({ lng: +longitude, lat: +latitude }), [longitude, latitude]);
  const offsets = useMemo(() => [0, 20] as [number, number], []);
  // const { ready } = useConfigShareReady();

  const onClickMarker = () => {
    if (latitude && longitude) {
      if (typeof onClick === 'function') onClick(data, { lnglat, adCode });
    }
  };

  return (
    <Marker lnglat={lnglat} offsets={offsets}>
      <div className={styles['marker-container']} onClick={onClickMarker} data-no={data.serialNo}>
        <div className={styles['marker-container-box']}>
          <div className={styles['img-container']}>
            <Image src={imgSrc} layout="responsive" width={120} height={120} />
          </div>
          {showCount && <div className={styles['count-container']}>{count}</div>}
          <div className={styles['popover-arrow']} />
        </div>
      </div>
    </Marker>
  );
};
