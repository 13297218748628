export enum MapLevel {
  GLOBAL = 'GLOBAL',
  COUNTRY = 'COUNTRY',
  PROVINCE = 'PROVINCE',
  CITY = 'CITY',
  COUNTY = 'COUNTY',
}
// https://baike.baidu.com/item/%E4%B8%AD%E5%8D%8E%E4%BA%BA%E6%B0%91%E5%85%B1%E5%92%8C%E5%9B%BD%E5%A4%A7%E5%9C%B0%E5%8E%9F%E7%82%B9/9075140
// 中国的大地原点经纬度
export const centerLagLnt = [32.32, 104.55];
// 地图缩放对应等级
export const levelSteps = [MapLevel.GLOBAL, MapLevel.COUNTRY, MapLevel.PROVINCE, MapLevel.CITY, MapLevel.COUNTY];
// 配置为数组
export const zoomLevelConfig = {
  [MapLevel.GLOBAL]: {
    value: MapLevel.GLOBAL,
    current: 2,
    level: [-Infinity, 3],
  }, // 全球
  [MapLevel.COUNTRY]: {
    value: MapLevel.COUNTRY,
    current: 3,
    level: [3, 5], // 国家
  },
  [MapLevel.PROVINCE]: {
    value: MapLevel.PROVINCE, // 省级
    current: 5,
    level: [5, 7],
  },
  [MapLevel.CITY]: {
    value: MapLevel.CITY, // 'CITY', // 市级
    current: 7,
    level: [7, 10],
  },
  [MapLevel.COUNTY]: {
    value: MapLevel.COUNTY, // 'COUNTY', // 市级
    current: 10,
    level: [10, +Infinity],
  },
};

/**
 * 获取地图的下一级
 */
export const getPrevStep = (level: MapLevel) => {
  const index = levelSteps.indexOf(level);
  const len = levelSteps.length;

  if (index + 1 < len) {
    return levelSteps[index + 1];
  }
  // 返回当前级别
  return levelSteps[index];
};

/**
 * 获取地图的上一级
 */
export const getNextStep = (level: MapLevel) => {
  const index = levelSteps.indexOf(level);

  if (index <= 0) {
    return false;
  }
  return levelSteps[index - 1];
};

export const getCurrentStepInfo = (level: MapLevel) => {
  return zoomLevelConfig[level];
};
/**
 * 获取当前 level 的配置
 * @param currentLevel
 * @returns
 */
export const getCurrentLevelConfig = (currentLevel: number) => {
  return Object.values(zoomLevelConfig).find((levelConfig) => {
    const { level } = levelConfig;
    const [firstLevel, lastLevel] = level;
    return currentLevel >= firstLevel && currentLevel < lastLevel;
  });
};

export const report =
  (onReport?: (params: { type: string; payload: any }) => void) => (type: string, payload?: any) => {
    const reportData = {
      type,
      payload,
    };

    onReport?.(reportData);
  };

/**
 * ACode对应的经纬度
 *
 */
export const NameCenter = {
  '110000': {
    lnglat: [116.4111328125, 39.90130858574736],
    name: '北京',
  },
  '120000': {
    lnglat: [117.2021484375, 39.08743603215885],
    name: '天津',
  },
  '130000': {
    lnglat: [114.993896484375, 38.57393751557592],
    name: '河北',
  },
  '140000': {
    name: '山西',
    lnglat: [111.917724609375, 37.67512527892127],
  },
  '150000': {
    name: '内蒙古',
    lnglat: [115.345458984375, 44.174324837518895],
  },
  '210000': {
    name: '辽宁',
    lnglat: [123.167724609375, 41.795888098191426],
  },
  '220000': {
    name: '吉林',
    lnglat: [126.068115234375, 43.60426186809619],
  },
  '230000': {
    name: '黑龙江',
    lnglat: [128.441162109375, 47.36115300722622],
  },
  '310000': {
    name: '上海',
    lnglat: [121.475830078125, 31.231591672050584],
  },
  '320000': {
    name: '江苏',
    lnglat: [119.1796875, 33.75174787568194],
  },
  '330000': {
    name: '浙江',
    lnglat: [119.8828125, 29.563901551414418],
  },
  '340000': {
    name: '安徽',
    lnglat: [116.982421875, 32.35212281198645],
  },
  '350000': {
    name: '	福建',
    lnglat: [117.7734375, 26.53939432901703],
  },
  '360000': {
    name: '江西',
    lnglat: [115.400390625, 28.178559849396976],
  },
  '370000': {
    name: '山东',
    lnglat: [118.388671875, 36.48314061639212],
  },
  '410000': {
    name: '河南',
    lnglat: [113.291015625, 34.11635246997275],
  },
  '420000': {
    name: '湖北',
    lnglat: [112.060546875, 31.306715155075167],
  },
  '430000': {
    name: '湖南',
    lnglat: [111.357421875, 27.945886221761967],
  },
  '440000': {
    name: '广东',
    lnglat: [113.939208984375, 24.236947003917507],
  },
  '450000': {
    name: '广西',
    lnglat: [108.6328125, 24.0765591202954],
  },
  '460000': {
    name: '海口',
    lnglat: [109.6875, 19.425153718960146],
  },
  '500000': {
    name: '重庆',
    lnglat: [106.556396484375, 29.563901551414418],
  },
  '510000': {
    name: '四川',
    lnglat: [102.744140625, 31.156408414556992],
  },
  '520000': {
    name: '贵州',
    lnglat: [106.787109375, 27.01019643193152],
  },
  '530000': {
    name: '云南',
    lnglat: [101.25, 24.47715001114868],
  },
  '540000': {
    name: '西藏',
    lnglat: [88.099365234375, 31.156408414556992],
  },
  '610000': {
    name: '陕西',
    lnglat: [108.544921875, 34.11635246997275],
  },
  '620000': {
    name: '甘肃',
    lnglat: [104.765625, 34.768691457552706],
  },
  '630000': {
    name: '青海',
    lnglat: [95.570068359375, 36.27085020723902],
  },
  '640000': {
    name: '宁夏',
    lnglat: [105.908203125, 37.396346133189226],
  },
  '650000': {
    name: '新疆',
    lnglat: [85.111083984375, 41.26954950284258],
  },
};

type ADCodeType = keyof typeof NameCenter;

export const getCenterByAdcode = (adcode: ADCodeType) => {
  const keys = Object.keys(NameCenter);
  if (keys.includes(adcode)) {
    return NameCenter[adcode].lnglat;
  }
  return [];
};
