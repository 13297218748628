import React, { FC, PropsWithChildren } from 'react';

import styles from './SuctionWrap.module.scss';

const SuctionWrap: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <div className={styles['bottom-column-wapper']}>
      <div className={styles['bottom-column-panel']}>{children}</div>
    </div>
  );
};

SuctionWrap.displayName = 'SuctionWrap';
SuctionWrap.defaultProps = {
  children: null,
};

export default SuctionWrap;
